import Navigation from 'components/navigation';
import './App.scss';

export default function App() {
  return (
    <>
      <Navigation />
    </>
  );
}
