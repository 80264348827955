import _ from 'lodash';
import axios from 'axios';
import config from 'config';
import { store, resetStore } from 'store';
import { setToken, setUser } from 'store/slices/auth-slice';

const defaultConfig = {
  baseURL: config.apiUrl,
};

const httpClient = axios.create(defaultConfig);

const authInterceptor = (config) => {
  if (!config || !config['Content-Type']) {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  }

  const { token } = store.getState().auth;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  (response) => {
    const { data, headers } = response;
    const { user } = data.payload;
    const { dispatch, getState } = store;

    let token = '';
    if (headers['access-token']) {
      token = headers['access-token'];
    }

    const { token: storedToken, user: userData } = getState().auth;
    if (token && token !== storedToken) {
      console.log({ token });
      dispatch(setToken(token));
    }

    if (typeof user !== 'undefined' && !_.isEqual(userData, user)) {
      console.log({ user });
      dispatch(setUser(user));
    }

    return response;
  },
  (err) => {
    const { response } = err;

    console.log({ err });

    //console.log({ response });

    /*
        // CONDICION MALDITA!
        if (typeof response === 'undefined') {
            return resetStore();
        }
        */

    const { error } = response?.data;
    //console.log({ httpStatus, error });

    if (error && error?.type === 'SERVER_ERROR' && error?.description === 'Expired token') {
      return resetStore();
    }

    /*
    if (!httpStatus || (httpStatus === 401 && error.type && error.type === 'UNAUTHENTICATED')) {
      return resetStore();
    }
    */

    return Promise.reject(err);
  }
);

export default httpClient;
