import { createSlice } from '@reduxjs/toolkit';
import { authInitialState } from 'store/states/auth-state';

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    resetAuthState: (state) => authInitialState,

    setToken: (state, { payload }) => {
      state.token = payload;
    },

    setUser: (state, { payload }) => {
      state.user = payload;
      state.isAuthenticated = true;
    },
  },
});

export const { resetAuthState, setToken, setUser } = authSlice.actions;

export default authSlice.reducer;
