import api from 'api';
import { resetStore } from 'store';

const authService = {
  signIn: async (email, password) => {
    try {
      const { data } = await api.post(`/auth/sign-in`, { email, password });
      const { user } = data.payload;
      return { user };
    } catch (error) {
      resetStore();
      return { user: null };
    }
  },

  signOut: async () => {
    try {
      await api.post(`/auth/sign-out`);
    } finally {
      resetStore();
      return { user: null };
    }
  },
};

export default authService;
