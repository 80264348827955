import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Slices
import authReducer, { resetAuthState } from './slices/auth-slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const reducers = combineReducers({
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [],
});

export const persistor = persistStore(store);

export function resetStore(callback = () => null) {
  const { dispatch } = store;

  dispatch(resetAuthState());

  if (callback && typeof callback === 'function') {
    callback();
  }
}
