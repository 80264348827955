import Logo from 'assets/images/360.gif';

export const Loading = ({ message, position, size }) => (
  <div
    style={{
      position: position || 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    <img src={Logo} alt="360 app" style={{ width: size || 120 }} />
    {message ? <span style={{ color: 'red', fontSize: 18 }}>{message}</span> : null}
  </div>
);
