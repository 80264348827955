import { useRef, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'components/ui/Header';
import { Menu } from 'components/ui/Menu';
import styles from './styles.module.scss';

export const Screen = ({ children, className }) => {
  const screenRef = useRef(null);
  const { isAuthenticated, user } = useSelector(({ auth }) => auth);

  console.log({ isAuthenticated, user });

  const handleResize = useCallback(() => {
    if (!screenRef || !screenRef.current) return;
    screenRef.current.style.minHeight = `${window.innerHeight}px`;
    screenRef.current.style.width = `${window.innerWidth}px`;
  }, [screenRef]);

  useEffect(() => handleResize(), [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => window.removeEventListener('resize', handleResize, true);
  }, [handleResize]);

  const header = useMemo(() => (isAuthenticated ? <Header user={user} /> : null), [isAuthenticated, user]);

  return (
    <div
      className={[styles.screen, className].join(' ')}
      ref={screenRef}
      style={{ paddingTop: isAuthenticated ? 50 : 0 }}
    >
      {header}
      <div className={styles.container}>
        {isAuthenticated ? (
          <div className={styles.menu}>
            <Menu />
          </div>
        ) : null}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
