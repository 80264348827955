import { memo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import config from 'config';

import AuthRouter from './auth';
import AdminRouter from './admin';
import EmployeesRouter from './employees';

export default function Navigation() {
  return (
    <BrowserRouter basename={config.routerBaseName}>
      <Routers />
    </BrowserRouter>
  );
}

const Routers = memo(() => {
  const { user, isAuthenticated } = useSelector(({ auth }) => auth);
  const roleId = user ? user.role_id : null;
  return isAuthenticated ? roleId === 1 ? <AdminRouter /> : <EmployeesRouter /> : <AuthRouter />;
});
