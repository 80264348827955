import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import service from 'services/authService';
import styles from './styles.module.scss';

export const Header = ({ user }) => {
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div>
              <div>
                <b style={{ display: 'inline-flex', width: 100 }}>Usuario:</b> {user.name}
              </div>
              <div>
                <b style={{ display: 'inline-flex', width: 100 }}>Email:</b> {user.email}
              </div>
              <div>
                <b style={{ display: 'inline-flex', width: 100 }}>Rol:</b> {user.role_name}
              </div>
              <div>
                <b style={{ display: 'inline-flex', width: 100 }}>Empresa:</b> {user.company_name}
              </div>
              <div>
                <b style={{ display: 'inline-flex', width: 100 }}>Aplicación:</b> {user.application_name}
              </div>
            </div>
          ),
        },
        {
          key: '2',
          danger: true,
          label: 'Cerrar Sesión',
          onClick: () => service.signOut(),
          style: { textAlign: 'center', fontWeight: 'bold' },
        },
      ]}
    />
  );

  return (
    <header className={styles.header}>
      <h2>Grupo Diana 360</h2>

      <Dropdown overlay={menu}>
        <a className={styles.link} onClick={(e) => e.preventDefault()}>
          <Space>
            {user.name}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </header>
  );
};
