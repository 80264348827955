import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from 'components/ui';

const SignInRoute = lazy(() => import('./SignInRoute'));

export default function AuthRouter() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<SignInRoute />} />
        <Route path="/sign-in" element={<SignInRoute />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Suspense>
  );
}
